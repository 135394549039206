import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/roles/PrivaterRoute';
import Dashboard from './container/tablero/dashboard';
import FormularioUsuario from './container/log/createUser';
import FormularioRepartidor from './container/log/crearRepartidor';
import FormularioSucursal from './container/log/crearSucursal';
import FormularioConductor from './container/log/crearConductor';
import Maps from './container/log/maps';
import Pruebas from './container/log/pruebas';
import InicarSesion from './container/log/login';
import DashboardDriver from './container/conductor/tablero/menu';
import Espera from './container/conductor/tablero/espera';
import Pedidos from './container/conductor/tablero/pedidos';
import EntregaFallida from './container/conductor/tablero/entregaFallida';
import NoAsignados from './container/conductor/tablero/noAsignados';
import Entregado from './container/conductor/tablero/entregado';
import EntregarNow from './container/conductor/tablero/irEntregar';
import Detalles from './container/conductor/tablero/detalles';
import InciarEntrega from './container/conductor/tablero/inicarEntrega';
import PagoMixto from './container/conductor/tablero/pagoMixto';
import PagoDigital from './container/conductor/tablero/pagoDigital';
import ProcesoPago from './container/conductor/tablero/procesoPago';
import PagoEfectivo from './container/conductor/tablero/pagoEfectivo';
import EntregaCheck from './container/conductor/tablero/entregaCheck';
import EntregaFail from './container/conductor/tablero/entregaFail';
import PedidosTabla from './container/tablas/pedidos';
import Visitas from './container/log/Visitas';
import Rutas from './container/log/rutas';
import ErrorPage from './container/404/404';
import UnauthorizedPage from './container/noAuth/noAuth';
import VisitasM from './container/log/visitasMultiples';
import AdminPanel from './container/admin/panelAdmin';
import FeeManager from './container/admin/fee';
import InventoryCRUD from './container/log/inventarioCrud';
import StatusUpdates from './container/conductor/tablero/StatusUpdates';
import PedidosTablaEdit from './container/log/updatePedido';
import TrackingComponent from './newInterface/buscarGuia/buscar';

//Interfaz nueva
import DashboardM from './newInterface/dashboard/dashboardMex';
import GuiaForm from './newInterface/Guia/guiaForm';
import TableComponent from './newInterface/novedades/novedades';
import RecoleccionTabla from './newInterface/recoleccion/recoleccionTabla/recoleccionTabla';
// import EnvioMasivo from './newInterface/Masivo/envioMasivo';
import TableComponentE from './newInterface/envioCompleto/envioCompleto';
import PaymentsModule from './newInterface/retiros/retiro';
import TableComponentC from './newInterface/carteraCompleta/cartera';
import HistoricoCartera from './newInterface/historicoCartera/historicoCartera';
import HistoricoEnvios from './newInterface/historicoEnvios/historicoEnvios';
import RechargeForm from './newInterface/recargas/recargas';
import Tutoriales from './newInterface/tutoriales/tutoriales';
import DynamicForm from './newInterface/pqr/pqr';
import ShippingForm2 from './newInterface/masivoFinal/masivoFinal';
import LoginRegistrationComponent from './newInterface/login-top/login-top';
import PreLogin from './container/log/preLogin/preLogin';
import GuiaSimple from './newInterface/Guia/guiaSimple';
import BitacoraCartera from './newInterface/bitacoraCartera/bitacoraCartera';
import DesactivarGuiaUser from './newInterface/eliminarGuia/eliminarGuia';
import WhatsappComponent from './whatsapp/whatsapp';

//Intefaz Admin Nueva
import DashboardAdminNew from './adminInterface/dashboard/dashboardAdmin';
import Modal from './adminInterface/modal/modal';
import ModalTransportadoras from './adminInterface/modalTransportadoras/modalTransportadoras';
import NewAdmin from './adminInterface/newadmin/newadmin';
import UserForm from './adminInterface/newuser/newuser';
import VendedorForm from './adminInterface/sucursalVendedor/sucursalVendedor';
import UserFormColaborador from './adminInterface/nuevoColaborador/nuevoColaborador';
import Pqr from './adminInterface/pqr/pqr';
import PqrFinalizado from './adminInterface/pqrfinalizado/pqrfinalizado';
import Pagos from './adminInterface/pagos/pagos';
import CrearSucursal from './adminInterface/crearSucursal/crearSucursal';
import VerSucursal from './adminInterface/crearSucursal/verSucursal';
import ConfigNegociacion from './adminInterface/configNegociacion/configNegociacion';
import ConfigTransportadora from './adminInterface/configTransportadora/configTransportadora';
import TablaInterrapidisimo from './adminInterface/tablaInterrapidisimo/tablaInterrapidisimo';
import TablaUsuariosAdmin from './adminInterface/tablaUsuarios/tablaUsuariosAdmin';
import ListarCuenta from './adminInterface/ListarCuenta/lsitarCuenta';
import DesactivarGuia from './adminInterface/eliminarGuia/eliminarGuia';
import CrearBodega from './adminInterface/crearBodega/crearBodega';
import ListaDeBodegas from './adminInterface/listarBodegas/listarBodega';
import ListarNovedadesAdmin from './adminInterface/listarNovedadesAdmin/listarNovedadesAdmin';
import BitacoraCarteraAdmin from './adminInterface/bitacoraAdmin/bitacora';
import WhatsappAdmin from './adminInterface/whatsapp/whatsappAdmin';
import Indemnizaciones from './adminInterface/indemnizaciones/indemnizaciones';

//Interfaz Dropshipping
import LandingDrop from './dropShipingInterface/landingDrop/landigDrop';
import DropGeneral from './dropShipingInterface/landingDrop/landingDropGeneral';
import ProductDetail from './dropShipingInterface/productDetail/productDrop';
import InventoryDrop from './dropShipingInterface/crudInventario/crud';
import SendClientPage from './dropShipingInterface/SendClientPage/SendClientPage';
import ProuductTable from './dropShipingInterface/misPedidos/misPedidos';
import ProductTablePedidos from './dropShipingInterface/misPedidos/sucursalPedidos';
import MasivoDrop from './dropShipingInterface/masivoDrop/masivoDrop';

//Interfaz Vendedor
import DashboardSales from './salerInterface/pages/dashboard/dashboardSales';
import PagoVendedores from './salerInterface/pages/pagos/pagosVendedor';
import DatosSucursales from './salerInterface/pages/datosSucursales/DatosSucursales';
import SucursalesComisionadas from './salerInterface/sucursalesComisionadas/SucursalesComisionadas';

//Interfaz Panel Usuario
import PanelUsuario from './newInterface/panelUsuario/panelUsuario';
import InicioUsuario from './newInterface/inicioUsuario/inicioUsuario';

//Interfaz Shopify
import Shopify from './shopifyInterface/Shopify';
import ShopifyOrdenes from './shopifyInterface/shopifyOrdenes/shopifyOrdenes';

//Interfaz Picking
import RegistroSuscriptor from './picking/moduloBodega/registroSuscriptor/resgistroSuscriptor';
import GestionInventarioBodega from './picking/interfazBodega/gestionInventarioBodega/gestionInventarioBodega';
import FormularioBodega from './picking/moduloBodega/formularioBodega/formularioBodega';
import OrdenesEnviosBodegas from './picking/moduloBodega/ordenesEnviosBodegas/ordenesEnviosBodegas';
import OrdenesyBodega from './picking/moduloUsuarios/ordenesBodega';
import InventariosBodega from './picking/moduloUsuarios/inventariosBodega';
import ShopifyOrdenesOrigen4 from './shopifyInterface/shopifyOrdenes/shopifyOrdenesOrigen4';

//Interfaz Picking Usuario
import ListarInventarioBodega from './picking/interfazBodega/listarInventarioBodega/listarInventarioBodega';
import InventarioBodega from './picking/interfazBodega/inventarioBodega/inventarioBodega';
import PersonalPicking from './picking/interfazBodega/personalPicking/personalPicking';
import PedidosPicking from './picking/interfazBodega/pedidosPicking/pedidosPicking';
import EstadoPedidos from './picking/interfazBodega/estadoPedidos/estadoPedidos';
import PedidosExternos from './picking/interfazBodega/pedidosExternos/pedidosExternos';
import EnviosEntrantes from './picking/interfazBodega/enviosEntrantes/enviosEntrantes';
import ListaDevoluciones from './picking/interfazBodega/listaDevoluciones/listaDevoluciones';

//Interfaz Picking Enlistment
import PedidosEnlistment from './picking/interfazEnlistment/pedidosEnlistment/pedidosEnlistment';

import './App.css';
import 'antd/dist/antd.css';
import '@fontsource/poppins';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          {/* conductor */}
          <Route path="/conductor" element={<PrivateRoute roles={[1,2,4]}><DashboardDriver /></PrivateRoute>} />
          {/* Rutas principales */}
          <Route path="/conductor/ruta-en-espera"element={<PrivateRoute roles={[1,2,4]}><Espera /></PrivateRoute>}/>
          <Route path="/conductor/entrega-fallida" element={<PrivateRoute roles={[1,2,4]}><EntregaFallida /></PrivateRoute>} />
          <Route path="/conductor/ruta-salir-entregar" element={<PrivateRoute roles={[1,2,4]}><EntregarNow /></PrivateRoute>} />
          <Route path="/conductor/ruta-entregado" element={<PrivateRoute roles={[1,2,4]}><Entregado /></PrivateRoute>} />
          <Route path="/conductor/ruta-no-asignado" element={<PrivateRoute roles={[1,2,4]}><NoAsignados /></PrivateRoute>} />
          {/* Detalles de pedidos */}
          <Route path="/conductor/pedidos/:pedidoId" element={<PrivateRoute roles={[1,2,4]}><Pedidos /></PrivateRoute>} />
          <Route path="/conductor/detalles/:id" element={<PrivateRoute roles={[1,2,4]}><Detalles /></PrivateRoute>} />
          {/* Procesos de entrega y pago */}
          <Route path="/conductor/iniciar-entrega/:id" element={<PrivateRoute roles={[1,2,4]}><InciarEntrega /></PrivateRoute>} />
          <Route path="/conductor/proceso-pago/:id" element={<PrivateRoute roles={[1,2,4]}><ProcesoPago /></PrivateRoute>} />
          <Route path="/conductor/pago-mixto/:id" element={<PrivateRoute roles={[1,2,4]}><PagoMixto /></PrivateRoute>} />
          <Route path="/conductor/pago-digital/:id" element={<PrivateRoute roles={[1,2,4]}><PagoDigital /></PrivateRoute>} />
          <Route path="/conductor/pago-efectivo/:id" element={<PrivateRoute roles={[1,2,4]}><PagoEfectivo /> </PrivateRoute>} />
          {/* Mensajes de entrega */}
          <Route path="/conductor/entrega-check" element={<PrivateRoute roles={[1,2,4]}><EntregaCheck /></PrivateRoute>} />
          {/* Novedades */}
          <Route path="/conductor/novedades/:pedidoId" element={<PrivateRoute roles={[1,2,4]}><StatusUpdates /></PrivateRoute>} />
          {/* Tablero */}
          <Route path="/conductor/entrega-fail" element={<PrivateRoute roles={[1,2,4]}><EntregaFail /></PrivateRoute>} />
          {/* Tablero */}
          <Route path="/tablero" element={<PrivateRoute roles={[1,2,3]}><Dashboard/></PrivateRoute>} />
          {/* Inicio de sesión */}

          <Route path="/" element={<PreLogin />} />
          <Route path="/login" element={<InicarSesion />} />
          <Route path="/login-top" element={<LoginRegistrationComponent />} />
          <Route path="/crear/usuario" element={<FormularioUsuario />} />
          <Route path="/cotizar" element={<GuiaSimple />} />

     

          {/* Brian */}
          <Route path="/crear/repartidor" element={<FormularioRepartidor/>} />
          <Route path="/crear/sucursal" element={<FormularioSucursal />} />
          <Route path="/crear/conductor" element={<PrivateRoute roles={[1,2,3]}><FormularioConductor/></PrivateRoute> }/>


          

          {/* Tablas  */}
          <Route path="/crear/pruebas" element={<PrivateRoute roles={[1,2,3]}><Pruebas/></PrivateRoute>} />
          {/* Hasta aquí va la documentación */}
          <Route path="/pedidos" element={<PrivateRoute roles={[1,2,3]}><PedidosTabla/></PrivateRoute>} />
          {/* crud editable pedidos */}
          <Route path="/pedidos/editar" element={<PrivateRoute roles={[1,2,3]}><PedidosTablaEdit/></PrivateRoute>} />
          {/* PedidosTabla */}
          <Route path="/pedido/vehiculo" element={<PrivateRoute roles={[1,2,3]}><Maps/></PrivateRoute>} />
          <Route path="/pedido/visitas/" element={<PrivateRoute roles={[1,2,3]}><Visitas/></PrivateRoute>} />
          <Route path='/pedido/rutas/' element={<PrivateRoute roles={[1,2,3]}><Rutas/></PrivateRoute>} />
          <Route path='/pedido/visitas-multiples' element={<PrivateRoute roles={[1,2,3]}><VisitasM/></PrivateRoute>} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/unauthorized" element={<UnauthorizedPage />} />

          {/*Inventario Crud*/}
          <Route path="/inventario" element={<InventoryCRUD />} />
          {/* Admin */}
          <Route path="/admin" element={<PrivateRoute roles={[1]}><AdminPanel/></PrivateRoute >}/>
          <Route path="/admin/fee" element={<PrivateRoute roles={[1]}><FeeManager/></PrivateRoute >} />

          {/* Nueva interfaz */}
          <Route path="/dashboardMex/" element={<DashboardM />} />
          <Route path="/guiaForm/:id" element={<GuiaForm />} />
          <Route path="/novedades" element={<TableComponent />} />
          <Route path="/recoleccion" element={<RecoleccionTabla />} />
          {/* <Route path="/guias-masivo/:id" element={<EnvioMasivo />} /> */}
          <Route path="/envios-completos" element={<TableComponentE />} />
          <Route path="/historico-envios" element={<HistoricoEnvios />} />
          <Route path="/retiros" element={<PaymentsModule />} />
          <Route path="/cartera-completa" element={<TableComponentC />} />
          <Route path="/historico-cartera" element={<HistoricoCartera />} />
          <Route path="/recargas" element={<RechargeForm />} />
          <Route path="/tutoriales" element={<Tutoriales />} />
          <Route path="/auto-pqr" element={<DynamicForm />} />
          <Route path="/buscar-guia" element={<TrackingComponent />} />
          <Route path="/masivo-final/:id" element={<ShippingForm2 />} />
          <Route path="/config-negociacion" element={<ConfigNegociacion />} />
          <Route path="/config-transportadora" element={<ConfigTransportadora />} />
          <Route path="/bitacora-cartera" element={<BitacoraCartera />} />
          <Route path="/desactivar-guia" element={<DesactivarGuiaUser />} />
          <Route path="/whatsapp" element={<WhatsappComponent />} />

          {/* interfaz admin nueva */}
          <Route path="/dashboardAdmin" element={<DashboardAdminNew />} />
          <Route path="/newuser" element={<UserForm />} />
          <Route path="/nuevo-colaborador" element={<UserFormColaborador />} />
          <Route path="/modal" element={<Modal />} />
          <Route path="/actualizar-estados" element={<ModalTransportadoras />} />
          <Route path="/newadmin" element={<NewAdmin />} />
          <Route path="/adminpqr" element={<Pqr />} />
          <Route path="/adminpqrfinalizado" element={<PqrFinalizado />} />
          <Route path="/pagos" element={<Pagos />} />
          <Route path="/sucursalVendedor" element={<VendedorForm />} />
          <Route path="/tablaInterrapidisimo" element={<TablaInterrapidisimo />} />
          <Route path="/tabla-usuarios" element={<TablaUsuariosAdmin />} />
          <Route path="/crear-sucursal" element={<CrearSucursal />} />
          <Route path="/ver-sucursal" element={<VerSucursal />} />
          <Route path="/listar-cuenta" element={<ListarCuenta />} />
          <Route path="/desactivar-guia-admin" element={<DesactivarGuia />} />
          <Route path="/gestionar-bodegas" element={<CrearBodega />} />
          <Route path="/listar-bodegas" element={<ListaDeBodegas />} />
          <Route path="/listar-novedades-admin" element={<ListarNovedadesAdmin />} />
          <Route path="/bitacora-admin" element={<BitacoraCarteraAdmin />} />
          <Route path="/whatsapp-admin" element={<WhatsappAdmin />} />
          <Route path="/indemnizaciones" element={<Indemnizaciones />} />

          {/* Interfaz Dropshipping  */}
          <Route path="/dropshipping" element={<LandingDrop />} />
          <Route path="/dropshippingGlobal" element={<DropGeneral  />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          <Route path="/inventarioDrop" element={<InventoryDrop />} />
          <Route path="/send/:id" element={<SendClientPage/>} />
          <Route path="/misPedidos" element={<ProuductTable />} />
          <Route path="/sucursalPedidos/" element={<ProductTablePedidos />} />
          <Route path="/masivoDrop" element={<MasivoDrop />} />
          
          {/* Interfaz Shopify  */}
          <Route path="/shopify" element={<Shopify />} />
          <Route path="/ordenes-shopify" element={<ShopifyOrdenes />} />

          {/* Interfaz Vendedor */}
          <Route path="/dashboardSales" element={<DashboardSales />} />
          <Route path="/PagoVendedores" element={<PagoVendedores />} />
          <Route path="/datosSucursales" element={<DatosSucursales />} />
          <Route path="/sucursalesComisionadas" element={<SucursalesComisionadas />} />
          
          {/* panel usuario */}
          <Route path="/panel-usuario" element={<PanelUsuario />} />

          {/* Interfaz Picking */}
          <Route path="/picking/registro-suscriptor" element={<RegistroSuscriptor />} />
          <Route path="/picking/formulario-bodega" element={<FormularioBodega />} />
          <Route path="/picking/translado-envio-inventarios" element={<OrdenesEnviosBodegas />} />
          <Route path="/picking/gestion-inventario-bodega" element={<GestionInventarioBodega />} />

          {/* Interfaz Picking Usuario*/}
          <Route path="/picking/inventario-entrante" element={<ListarInventarioBodega />} />
          <Route path="/picking/inventario-bodega" element={<InventarioBodega />} />
          <Route path="/picking/envios-entrantes" element={<EnviosEntrantes />} />
          <Route path="/picking/ordenes-bodega" element={<OrdenesyBodega />} />
          <Route path="/picking/personal-picking" element={<PersonalPicking />} />
          <Route path="/picking/pedidos-picking" element={<PedidosPicking />} />
          <Route path="/picking/estado-pedidos" element={<EstadoPedidos />} />
          <Route path="/picking/pedidos-externos" element={<PedidosExternos />} />
          <Route path="/picking/ordenes-picking" element={<ShopifyOrdenesOrigen4 />} />
          <Route path="/picking/lista-devoluciones" element={<ListaDevoluciones />} />

          {/* Interfaz Picking Enlistment */}
          <Route path="/picking/pedidos-enlistador" element={<PedidosEnlistment />} />

          {/* Ordenes y Bodega */}
          <Route path="/picking/inventarios-bodega" element={<InventariosBodega />} />

          {/* Inicio */}
          <Route path="/inicio" element={<InicioUsuario />} />

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
import React, { useState } from 'react';
import NavbarAdminMex from '../components/navbar';
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';
import { message } from 'antd';
import './indemnizaciones.css';

const Indemnizaciones = () => {
  const [indemTrackingNumberPreenvio, setIndemTrackingNumberPreenvio] = useState('');
  const [indemCarrierPreenvio, setIndemCarrierPreenvio] = useState('interrapidisimo');
  const [indemTrackingNumberServicio, setIndemTrackingNumberServicio] = useState('');
  const [indemCarrierServicio, setIndemCarrierServicio] = useState('interrapidisimo');

  const handleIndemnizacionPreenvio = async () => {
    const requestBody = {
      guia: indemTrackingNumberPreenvio,
      transportadora: {
        pais: 'colombia',
        nombre: indemCarrierPreenvio
      }
    };
    try {
      const userId = localStorage.getItem('usuario_id');
      const response = await fetch(`https://integration.99envios.app/api/indemnizacion-preenvio/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
      const data = await response.json();
      message.info(data.message || 'No se recibió ningún mensaje de la API');
    } catch (error) {
      message.error(`Error inesperado: ${error.message}`);
    }
  };

  const handleIndemnizacionServicioPreenvio = async () => {
    const requestBody = {
      guia: indemTrackingNumberServicio,
      transportadora: {
        pais: 'colombia',
        nombre: indemCarrierServicio
      }
    };
    try {
      const userId = localStorage.getItem('usuario_id');
      const response = await fetch(`https://integration.99envios.app/api/indemnizacion-servicio-preenvio/3708${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
      const data = await response.json();
      message.info(data.message || 'No se recibió ningún mensaje de la API');
    } catch (error) {
      message.error(`Error inesperado: ${error.message}`);
    }
  };

  return (
    <>
      <NavbarAdminMex title="Indemnizaciones" />
      <div className="indemnizaciones-container">
        <div className="indemnizacion-box">
          <h2>INDEMNIZACIÓN SERVICIO</h2>
          <div className="search-bar">
            <input
              type="text"
              value={indemTrackingNumberPreenvio}
              onChange={(e) => setIndemTrackingNumberPreenvio(e.target.value)}
              placeholder="Ingrese número de guía"
              className="search-input"
            />
            <select
              value={indemCarrierPreenvio}
              onChange={(e) => setIndemCarrierPreenvio(e.target.value)}
              className="carrier-select"
            >
              <option value="interrapidisimo">Interrapidisimo</option>
              <option value="servientrega">Servientrega</option>
              <option value="coordinadora">Coordinadora</option>
              <option value="tcc">TCC</option>
            </select>
            <button onClick={handleIndemnizacionPreenvio} className="search-button">
              Enviar
            </button>
          </div>
        </div>

        <div className="indemnizacion-box">
          <h2>INDEMNIZACIÓN VALOR SERVICIO</h2>
          <div className="search-bar">
            <input
              type="text"
              value={indemTrackingNumberServicio}
              onChange={(e) => setIndemTrackingNumberServicio(e.target.value)}
              placeholder="Ingrese número de guía"
              className="search-input"
            />
            <select
              value={indemCarrierServicio}
              onChange={(e) => setIndemCarrierServicio(e.target.value)}
              className="carrier-select"
            >
              <option value="interrapidisimo">Interrapidisimo</option>
              <option value="servientrega">Servientrega</option>
              <option value="coordinadora">Coordinadora</option>
              <option value="tcc">TCC</option>
            </select>
            <button onClick={handleIndemnizacionServicioPreenvio} className="search-button">
              Enviar
            </button>
          </div>
        </div>
      </div>
      <BotonWhatsapp />
    </>
  );
};

export default Indemnizaciones;

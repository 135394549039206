import React, { useState, useEffect } from 'react';
import { Modal, Button, message, Table, Radio, Spin, Select, Alert, Tabs } from 'antd';
import BotonWhatsapp from '../botonWhatsapp/botonWhatsapp';
import NavbarMex from '../components/navbarMex';
import { utils, writeFile } from 'xlsx';
import './PaymentsModule.css';
import TransferenciaSucursal from './transferenciaSucursal';

const { Option } = Select;
const { TabPane } = Tabs;

// Componente RetirosSucursal sin el NavbarMex
const RetirosSucursal = () => {
   const [availableBalance, setAvailableBalance] = useState(null);
   const [withdrawalAmount, setWithdrawalAmount] = useState('');
   const [withdrawals, setWithdrawals] = useState([]);
   const [banks, setBanks] = useState([]);
   const [selectedBankId, setSelectedBankId] = useState(null);
   const [error, setError] = useState('');
   const [sucursal] = useState(localStorage.getItem('usuario_id'));
   const [sucursalId, setSucursalId] = useState(null);
   const [showBankForm, setShowBankForm] = useState(false);
   const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
   const [isWarningModalVisible, setIsWarningModalVisible] = useState(false); // Modal de advertencia
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [isRegistering, setIsRegistering] = useState(false);
   const [loading, setLoading] = useState(false);

   const [withdrawalFilters, setWithdrawalFilters] = useState({
      status: null
   });

   const [bankData, setBankData] = useState({
      codigo_banco: '',
      tipo_identificacion: '',
      numero_identificacion: '',
      tipo_cuenta: 2,
      numero_cuenta: ''
   });
   
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [lastThreeWithdrawalsSum, setLastThreeWithdrawalsSum] = useState(0); // Estado para la suma de los últimos 3 retiros
   const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

   const handleEditBank = (bank) => {
      setBankData({
         id: bank.id_banco || '',  // Asegúrate de incluir el id del banco aquí
         codigo_banco: bank.codigo_banco || '001',
         tipo_identificacion: bank.tipo_identificacion || '03',
         numero_identificacion: bank.numero_identificacion || '',
         tipo_cuenta: bank.tipo_cuenta || 2,
         numero_cuenta: bank.numero_cuenta || '',
      });
      setIsModalVisible(true);
   };

   const handleDeleteBank = async (bankId) => {
      try {
         const token = localStorage.getItem('token');
         const response = await fetch(`https://99envios.app/api/online/bank-sucursal/${bankId}`, {
            method: 'DELETE',
            headers: {
               Authorization: `Bearer ${token}`,
            },
         });

         if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al eliminar el banco');
         }

         message.success('Banco eliminado exitosamente');
         fetchAvailableBalance(); // Refrescar la lista de bancos

      } catch (err) {
         message.error(err.message || 'Error al eliminar el banco');
         console.error(err);
      }
   };

   useEffect(() => {
      const fetchSucursalId = async () => {
         try {
            const response = await fetch(`https://99envios.app/api/online/sucursal-codigo-sucursal/${sucursal}`);
            if (!response.ok) {
               throw new Error('Error fetching sucursal ID');
            }
            const data = await response.json();
            console.log('Sucursal ID:', data);
            setSucursalId(data); // Actualiza el estado con el ID de la sucursal
         } catch (error) {
            console.error('Error fetching sucursal ID:', error);
            return null;
         }
      };

      fetchSucursalId();
   }, [sucursal]);

   useEffect(() => {
      fetchAvailableBalance();
   }, []);

   const fetchAvailableBalance = async () => {
      try {
         const user_id = localStorage.getItem('usuario_id');
         console.log('User ID:', user_id);
         const token = localStorage.getItem('token');

         const response = await fetch(`https://99envios.app/api/online/consultar-saldo/${user_id}`, {
            method: 'GET',
            headers: {
               'Authorization': token ? `Bearer ${token}` : '',
               'Content-Type': 'application/json'
            }
         });

         if (!response.ok) throw new Error('Failed to fetch balance');

         const data = await response.json();
         setAvailableBalance(data.saldo_disponible);
         setWithdrawals(data.retiros || []);
         setBanks(data.bancos || []);
         
         // Actualizar solo el contador de retiros del mes actual
         const currentDate = new Date();
         const currentMonth = currentDate.getMonth();
         const currentYear = currentDate.getFullYear();
         
         const currentMonthWithdrawals = (data.retiros || []).filter(withdrawal => {
            const withdrawalDate = new Date(withdrawal.Fecha);
            return withdrawalDate.getMonth() === currentMonth && withdrawalDate.getFullYear() === currentYear;
         });
         
         setLastThreeWithdrawalsSum(currentMonthWithdrawals.length);

      } catch (err) {
         setError('Error al obtener el saldo disponible');
         console.error(err);
      }
   };

   // Función para filtrar los retiros
   const getFilteredWithdrawals = () => {
      return withdrawals.filter(withdrawal => {
        // Solo mantener el filtro por estado
        if (withdrawalFilters.status && withdrawal.estado !== withdrawalFilters.status) return false;
        return true;
      });
   };

   // Columnas para la tabla de retiros
   const withdrawalColumns = [
      {
         title: 'Fecha',
         dataIndex: 'Fecha',
         key: 'Fecha',
         sorter: (a, b) => new Date(a.Fecha) - new Date(b.Fecha),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: 'Valor Solicitado',
         dataIndex: 'Monto',
         key: 'Monto',
         render: (monto) => formatBalance(parseFloat(monto)),
         sorter: (a, b) => parseFloat(a.Monto) - parseFloat(b.Monto),
         sortDirections: ['ascend', 'descend']
      },
      {
         title: 'Estado',
         dataIndex: 'estado',
         key: 'estado',
      }
   ];
 
   // Componente de filtros
   const FilterComponent = () => (
      <div className="withdrawal-filters">
        <Select
          placeholder="Estado"
          allowClear
          value={withdrawalFilters.status}
          onChange={(value) => setWithdrawalFilters(prev => ({ ...prev, status: value }))}
          className="status-filter"
          style={{ width: '200px' }}  // Añadido para mejorar la apariencia
        >
          <Option value="">Todos</Option>
          <Option value="pagado">Pagado</Option>
          <Option value="pendiente">Pendiente</Option>
          <Option value="rechazado">Rechazado</Option>
        </Select>
      </div>
   );

   //  Función para calcular la suma de los últimos tres retiros
   // const calculateLastThreeWithdrawalsSum = (withdrawals) => {
   //    const lastThree = withdrawals.slice(-3); // Obtiene los últimos 3 retiros
   //    const sum = lastThree.reduce((acc, withdrawal) => acc + parseFloat(withdrawal.Monto), 0);
   //    setLastThreeWithdrawalsSum(sum);
   // };

   const formatBalance = (balance) => {
      if (balance === null) return 'Cargando...';
      const absBalance = Math.abs(balance);
      const formattedBalance = absBalance.toLocaleString('es-CO', {
         style: 'currency',
         currency: 'COP',
         minimumFractionDigits: 0,
         maximumFractionDigits: 0
      });
      return balance < 0 ? `-${formattedBalance}` : formattedBalance;
   };

   const handleWithdrawal = (e) => {
      e.preventDefault();
      if (!selectedBankId) {
         setIsWarningModalVisible(true); // Mostrar modal de advertencia si no se ha seleccionado una cuenta
         return;
      }

      if (!withdrawalAmount || isNaN(withdrawalAmount) || withdrawalAmount <= 0) {
         setError('Por favor, ingrese una cantidad válida para retirar');
         return;
      }

      setIsConfirmModalVisible(true); // Mostrar modal de confirmación si todo está correcto
   };

   const confirmWithdrawal = async () => {
      try {
         const token = localStorage.getItem('token');
         const user_id = localStorage.getItem('usuario_id');
         const response = await fetch(`https://99envios.app/api/online/retirar-saldo/${user_id}`, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ monto_retiro: parseFloat(withdrawalAmount), id_banco: selectedBankId }), // Include selectedBankId
         });

         if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al procesar el retiro');
         }

         const data = await response.json();

         setAvailableBalance(data.nuevo_saldo);
         setWithdrawals([
            {
               date: new Date().toISOString().split('T')[0],
               amount: withdrawalAmount,
               status: 'PROCESADO',
            },
            ...withdrawals,
         ]);

         setWithdrawalAmount('');
         setError('');
         fetchAvailableBalance(); // Actualiza la lista de retiros
         setIsConfirmModalVisible(false); // Ocultar el modal de confirmación
      } catch (err) {
         setError(err.message || 'Error al procesar el retiro');
         console.error(err);
         setIsConfirmModalVisible(false); // Ocultar el modal de confirmación en caso de error
      }
   };

   const handleBankDataChange = (e) => {
      const { name, value } = e.target;
      setBankData({
         ...bankData,
         [name]: value,
      });
   };

   // Cuando se abre el modal para crear o editar, asegurarse de cargar el valor predeterminado correctamente
   useEffect(() => {
      if (isModalVisible && !bankData.codigo_banco) {
         setBankData((prevData) => ({
            ...prevData,
            codigo_banco: prevData.codigo_banco || '001',
            tipo_identificacion: prevData.tipo_identificacion || '03',
            tipo_cuenta: prevData.tipo_cuenta || 2,
         }));
      }
   }, [isModalVisible]);

   const handleBankDataSubmit = async () => {
      // Verifica que todos los campos requeridos tengan valores
      if (!bankData.tipo_identificacion) {
         message.error('Por favor, selecciona el tipo de identificación');
         return;
      }
      if (!bankData.tipo_cuenta) {
         message.error('Por favor, selecciona el tipo de cuenta');
         return;
      }

      // Continuar con el envío si todo está correcto
      console.log('Datos a enviar:', bankData);  // Depuración: Verificar qué valores se están enviando
      if (isRegistering) return;

      setIsRegistering(true);
      setLoading(true);

      try {
         const token = localStorage.getItem('token');
         const method = bankData.id ? 'PUT' : 'POST';
         const url = bankData.id ? `https://99envios.app/api/online/bank-sucursal/${bankData.id}` : `https://99envios.app/api/online/bank-sucursal`;

         const response = await fetch(url, {
            method: method,
            headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
               codigo_sucursal: sucursalId,
               codigo_banco: bankData.codigo_banco,
               numero_cuenta: bankData.numero_cuenta,
               tipo_cuenta: bankData.tipo_cuenta,  // Asegúrate de enviar este campo
               numero_identificacion: bankData.numero_identificacion,
            }),
         });

         if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error al registrar los datos bancarios');
         }

         const data = await response.json();
         message.success('Datos bancarios registrados exitosamente');
         setIsModalVisible(false); // Cerrar el modal
         setBankData({
            codigo_banco: '',
            tipo_identificacion: '',
            numero_identificacion: '',
            tipo_cuenta: 2,
            numero_cuenta: ''
         });
         fetchAvailableBalance(); // Refrescar la lista de bancos

      } catch (err) {
         message.error(err.message || 'Error al registrar los datos bancarios');
         console.error(err);
      } finally {
         setIsRegistering(false);
         setLoading(false);
      }
   };

   const showModal = () => {
      // Restablecer los datos bancarios para un nuevo registro
      setBankData({
         codigo_banco: '',
         tipo_identificacion: '',
         numero_identificacion: '',
         tipo_cuenta: 2,
         numero_cuenta: ''
      });
      setIsModalVisible(true);
   };

   const handleCancel = () => {
      setIsModalVisible(false);
   };

   const onSelectBank = (id) => {
      setSelectedBankId(id);
   };

   const handleConfirmWithdrawal = async () => {
      if (isSubmitting) return; // Evita múltiples clics

      setIsSubmitting(true); // Indica que el proceso está en curso
      setLoading(true); // Muestra la rueda de carga

      try {
         await confirmWithdrawal(); // Lógica para confirmar el retiro
         setIsConfirmModalVisible(false); // Cierra el modal tras éxito
         message.success('Retiro procesado exitosamente');
      } catch (error) {
         console.error('Error during withdrawal confirmation:', error);
         message.error('Error al procesar el retiro');
      } finally {
         setIsSubmitting(false); // Permite nuevamente interactuar con el botón
         setLoading(false); // Quita la rueda de carga
      }
   };

   const formatDateForDisplay = (dateString) => {
      if (!dateString) return '';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return dateString; // Return original if invalid
      return date.toISOString().split('T')[0];
   };
  
   const exportToExcel = () => {
   // Map withdrawals data to a format suitable for Excel export
   const data = withdrawals.map(withdrawal => ({
      Fecha: formatDateForDisplay(withdrawal.Fecha),
      'Valor Solicitado': formatBalance(parseFloat(withdrawal.Monto)),
      Estado: withdrawal.estado
   }));
   
   const worksheet = utils.json_to_sheet(data);
   const workbook = utils.book_new();
   utils.book_append_sheet(workbook, worksheet, "Retiros");
   
   // Generate filename with current date
   const filename = `Retiros_${formatDateForDisplay(new Date())}.xlsx`;
   
   writeFile(workbook, filename);
   };

   const showVideoModal = () => {
      setIsVideoModalVisible(true);
   };

   const handleVideoModalCancel = () => {
      setIsVideoModalVisible(false);
   };

   const columns = [
      {
         title: 'Banco',
         dataIndex: 'nombre_banco',
         key: 'nombre_banco',
      },
      {
         title: 'Número de Cuenta',
         dataIndex: 'numero_cuenta',
         key: 'numero_cuenta',
      },
      {
         title: 'Acciones',
         key: 'actions',
         render: (text, record) => (
            <>
               <Radio
                  checked={selectedBankId === record.id_banco}
                  onChange={() => onSelectBank(record.id_banco)}
               />
               <Button style={{ backgroundColor: "#546bf9", color: "#f9ffff", width: "100px" }} onClick={() => handleEditBank(record)}>Editar</Button>
               <Button style={{ backgroundColor: "#dc5555", color: "#f9ffff", width: "100px" }} onClick={() => handleDeleteBank(record.id_banco)}>Eliminar</Button> {/* Botón de eliminar */}
            </>
         ),
      },
   ];


   return (
      <div className="payments-module">
         <h2 className="module-title">Módulo de Pagos</h2>
         <Alert
            message={
               <>
                  Para utilizar el módulo de pagos y retiros, consulta el siguiente video instructivo:{" "}
                  <Button type="link" onClick={showVideoModal} style={{ padding: 0 }}>
                     Ver video aquí.
                  </Button>
               </>
            }
            type="info"
            showIcon
            style={{ marginBottom: 16 }}
         />

         {/* Modal del video separado */}
         <Modal
            title="Instructivo de Pagos y Retiros"
            visible={isVideoModalVisible}
            onCancel={handleVideoModalCancel}
            footer={null}
            destroyOnClose={true}
            width="100vh"
            style={{ top: 20 }}
            bodyStyle={{ padding: 0 }}
         >
            <div style={{ position: 'relative', paddingBottom: '65%', height: 0, overflow: 'hidden' }}>
               <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/HAdLXKLF0eI?si=GsopzTWh8VaPYQyx"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                     position: 'absolute',
                     top: 0,
                     left: 0,
                     width: '100%',
                     height: '100%',
                  }}
               ></iframe>
            </div>
         </Modal>

         <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
               type="primary"
               onClick={showModal}
               style={{ backgroundColor: "#5b6bdf", height: "44px", width: "210px", marginLeft:0, marginRight:0 }}
            >
               Añadir Datos Bancarios
            </Button>
         </div>
         <div className="card-container">
            <div className="card total-available">
               <i className="material-icons">account_balance</i>
               <div className="card-content">
                  <p>Total Disponible</p>
                  <h2>{formatBalance(availableBalance)}</h2>
               </div>
            </div>
            <div className="card withdrawals">
               <i className="material-icons">favorite</i>
               <div className="card-content">
                  <p>Cantidad de Retiros del Mes Actual</p>
                  <h2>{lastThreeWithdrawalsSum}</h2>
                  <p className="info-text">
                     ¡Emocionantes noticias! A partir de ahora, tienen tres retiros mensuales gratuitos.
                     Y, además, hemos reducido el costo de los retiros adicionales a $3,000. ¡Más beneficios para ustedes! 🌟
                  </p>
               </div>
            </div>
         </div>
         <form className="withdrawal-form" onSubmit={handleWithdrawal}>
            <label htmlFor="amount">Cantidad a Retirar</label>
            <input
               type="number"
               id="amount"
               name="amount"
               placeholder="0"
               value={withdrawalAmount}
               onChange={(e) => setWithdrawalAmount(e.target.value)}
            />
            <button type="submit">Enviar</button>
         </form>
         {error && <div className="error-message">{error}</div>}

         <div className="bank-list">
            <h3>Bancos Asociados</h3>
            <Table
               dataSource={banks}
               columns={columns}
               rowKey="id_banco"
               pagination={false}
               className="table-container"
            />
         </div>

         <div className="info-text">
            <p>Nos encargamos de tus envíos, y queremos asegurarnos de que recibas tu pago sin problemas. Asegúrate de proporcionar tu información bancaria para que podamos hacerlo realidad.</p>
            <Alert
               message={
                  <>
                     ¡Importante!
                     Si tu pago ha sido rechazado, esto puede deberse a que los datos bancarios proporcionados no son correctos.
                     Verifica cuidadosamente la información ingresada y realiza las correcciones necesarias.
                  </>
               }
               type="info"
               showIcon
               style={{ marginBottom: 16 }}
            />
         </div>

         <Modal
            title={bankData.id ? "Editar Datos Bancarios" : "Añadir Datos Bancarios"}
            visible={isModalVisible}
            onOk={handleBankDataSubmit}
            onCancel={handleCancel}
            footer={[
               <Button key="back" onClick={handleCancel}>
                  Cancelar
               </Button>,
               <Button
                  key="submit"
                  type="primary"
                  loading={isRegistering}
                  onClick={handleBankDataSubmit}
               >
                  {isRegistering ? 'Registrando...' : bankData.id ? 'Editar' : 'Registrar'}
               </Button>
            ]}
         >



            <form className="bank-form">
               <label htmlFor="nombre_banco">Nombre banco</label>
               <select name="codigo_banco" id="nombre_banco" value={bankData.codigo_banco} onChange={handleBankDataChange}>
                  <option value="" disabled>Seleccione su banco</option>
                  <option value="001">Banco de Bogotá</option>
                  <option value="002">Banco Popular</option>
                  <option value="006">Banco Itau</option>
                  <option value="007">Bancolombia</option>
                  <option value="009">Citibank</option>
                  <option value="012">Banco GNB Sudameris</option>
                  <option value="013">BBVA Colombia</option>
                  <option value="019">Scotiabank Colpatria</option>
                  <option value="023">Banco de Occidente</option>
                  <option value="031">Bancoldex</option>
                  <option value="032">Banco Caja Social BCSC</option>
                  <option value="040">Banco Agrario</option>
                  <option value="041">JP Morgan corporación Financión</option>
                  <option value="042">BNP Paribas</option>
                  <option value="047">Banco Mundo Mujer</option>
                  <option value="051">Davivienda</option>
                  <option value="052">Banco AVVillas</option>
                  <option value="053">Banco W S.A.</option>
                  <option value="059">Bancamia S.A</option>
                  <option value="060">Banco Pichincha</option>
                  <option value="061">Bancoomeva</option>
                  <option value="062">Banco Fallabella</option>
                  <option value="065">Santander</option>
                  <option value="066">Banco Cooperativo Coopcentral</option>
                  <option value="069">Banco Serfinanza</option>
                  <option value="070">Lulo Bank</option>
                  <option value="071">Banco JP Morgan Colombia S.A.</option>
                  <option value="292">Confiar</option>
                  <option value="303">Banco Union SA</option>
                  <option value="370">Coltefinanaciera</option>
                  <option value="558">Ban100</option>
                  <option value="560">Pibank</option>
                  <option value="637">Iris</option>
                  <option value="803">Powwi</option>
                  <option value="804">Ualá</option>
                  <option value="805">Banco BTG Pactual</option>
                  <option value="811">Rappipay</option>
                  <option value="812">Coink</option>
               </select>

               <label htmlFor="tipo_identificacion">Tipo de identificación:</label>
               <select id="tipo_identificacion" name="tipo_identificacion" value={bankData.tipo_identificacion || "03"} onChange={handleBankDataChange} required>
                  <option value="03">CC</option>
                  <option value="04">CE</option>
                  <option value="04">PA</option>
                  <option value="02">RUT</option>
                  <option value="01">NIT</option>
               </select>

               <label htmlFor="numero_identificacion">Identificación del titular:</label>
               <input type="text" id="numero_identificacion" name="numero_identificacion" value={bankData.numero_identificacion} onChange={handleBankDataChange} placeholder="Identificación" required />

               <label htmlFor="tipo_cuenta">Tipo de Cuenta</label>
               <select name="tipo_cuenta" id="tipo_cuenta" value={bankData.tipo_cuenta || 2} onChange={handleBankDataChange}>
                  <option value={2}>Ahorros</option>
                  <option value={4}>Corriente</option>
               </select>

               <label htmlFor="numero_cuenta">Número de cuenta</label>
               <input type="number" id="numero_cuenta" name="numero_cuenta" value={bankData.numero_cuenta} onChange={handleBankDataChange} required />
            </form>
         </Modal>

         <Modal
            title="Confirmar Retiro"
            visible={isConfirmModalVisible}
            onCancel={() => setIsConfirmModalVisible(false)}
            footer={[
               <Button key="back" onClick={() => setIsConfirmModalVisible(false)} disabled={isSubmitting}>
                  Cancelar
               </Button>,
               <Button
                  key="submit"
                  type="primary"
                  loading={isSubmitting}
                  onClick={handleConfirmWithdrawal}
                  disabled={isSubmitting}
               >
                  {isSubmitting ? 'Procesando...' : 'Confirmar'}
               </Button>,
            ]}
         >
            <Spin spinning={loading}>
               <p>¿Estás seguro de que deseas retirar {formatBalance(parseFloat(withdrawalAmount))}?</p>
            </Spin>
         </Modal>

         <Modal
            title="Advertencia"
            visible={isWarningModalVisible}
            onOk={() => setIsWarningModalVisible(false)}
            onCancel={() => setIsWarningModalVisible(false)}
            okText="Entendido"
         >
            <p>Por favor, seleccione una cuenta bancaria antes de realizar un retiro.</p>
         </Modal>
           

         <div className="withdrawals-table">
            <div className="table-controls-retiros-realizados">
               <h3>Retiros realizados</h3>
               <div className="export-group">
                  <button onClick={exportToExcel} style={{width: 180}} className="export-button">
                     Exportar a Excel
                  </button>
               </div>
            </div>
            
            <FilterComponent />
            
            <Table
               dataSource={getFilteredWithdrawals()}
               columns={withdrawalColumns}
               rowKey={(record, index) => index}
               pagination={{
                  pageSize: 10,
                  showSizeChanger: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} retiros`
               }}
               locale={{
                  triggerAsc: 'Ordenar de menor a mayor',
                  triggerDesc: 'Ordenar de mayor a menor',
                  cancelSort: 'Cancelar ordenamiento'
               }}
            />
         </div>
      </div>
   );
};

// Nuevo componente principal que incluye las pestañas
const PaymentsModule = () => {
    return (
        <>
            <NavbarMex title="Retiros" />
            <div className="payments-module-container">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Retiros Sucursal" key="1">
                        <RetirosSucursal />
                    </TabPane>
                    <TabPane tab="Transferencias entre Sucursales" key="2">
                        <TransferenciaSucursal />
                    </TabPane>
                </Tabs>
                <BotonWhatsapp />
            </div>
        </>
    );
};

export default PaymentsModule;
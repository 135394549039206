import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Input, message, Form, Card, Tooltip, Badge, Empty } from 'antd';
import { SendOutlined, InfoCircleOutlined, DollarOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './transferenciaSucursal.css';

const { TextArea } = Input;

const TransferenciaSucursal = () => {
    const [saldoDisponible, setSaldoDisponible] = useState(0);
    const [transferenciasEnviadas, setTransferenciasEnviadas] = useState([]);
    const [transferenciasRecibidas, setTransferenciasRecibidas] = useState([]);
    const [loading, setLoading] = useState(false);
    
    // Estados para el flujo de transferencia
    const [correoSucursal, setCorreoSucursal] = useState('');
    const [isCorreoModalVisible, setIsCorreoModalVisible] = useState(false);
    const [detalleSucursal, setDetalleSucursal] = useState(null);
    const [isDetalleModalVisible, setIsDetalleModalVisible] = useState(false);
    const [isTransferenciaModalVisible, setIsTransferenciaModalVisible] = useState(false);
    const [montoTransferencia, setMontoTransferencia] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Función para formatear el número a pesos colombianos
    const formatToCurrency = (value) => {
        if (!value) return '';
        // Eliminar cualquier caracter que no sea número
        const cleanValue = value.toString().replace(/[^\d]/g, '');
        // Formatear con puntos
        return new Intl.NumberFormat('es-CO').format(cleanValue);
    };

    // Función para limpiar el formato y obtener solo números
    const cleanCurrencyFormat = (value) => {
        return value.toString().replace(/[^\d]/g, '');
    };

    useEffect(() => {
        fetchSaldoDisponible();
        fetchTransferencias();
    }, []);

    const fetchSaldoDisponible = async () => {
        try {
            const user_id = localStorage.getItem('usuario_id');
            const token = localStorage.getItem('token');
            const response = await fetch(`https://99envios.app/api/online/consultar-saldo/${user_id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setSaldoDisponible(data.saldo_disponible);
        } catch (error) {
            message.error('Error al obtener el saldo disponible');
        }
    };

    const fetchTransferencias = async () => {
        try {
            const user_id = localStorage.getItem('usuario_id');
            const token = localStorage.getItem('token');
            const response = await fetch(`https://99envios.app/api/online/listar-transferencias/${user_id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setTransferenciasEnviadas(data.transferencias_enviadas || []);
            setTransferenciasRecibidas(data.transferencias_recibidas || []);
        } catch (error) {
            message.error('Error al obtener el historial de transferencias');
        }
    };

    const fetchDetalleSucursal = async () => {
        try {
            if (!correoSucursal) {
                message.error('Por favor ingrese un correo');
                return;
            }

            const user_id = localStorage.getItem('usuario_id');
            const token = localStorage.getItem('token');

            const response = await fetch(`https://99envios.app/api/online/detalle-sucursal/${user_id}?correo=${encodeURIComponent(correoSucursal)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Error al obtener detalles de la sucursal');
            }

            const data = await response.json();
            if (data && data.length > 0) {
                setDetalleSucursal(data[0]);
                setIsCorreoModalVisible(false);
                setIsDetalleModalVisible(true);
            } else {
                message.error('No se encontró información de la sucursal');
            }
        } catch (error) {
            message.error(error.message);
        }
    };

    const handleDetalleConfirm = () => {
        setIsDetalleModalVisible(false);
        setIsTransferenciaModalVisible(true);
    };

    const handlePreTransferencia = () => {
        if (isSubmitting) return; // Evitar múltiples clicks mientras se procesa

        if (!descripcion.trim()) {
            message.error('El concepto de la transferencia es obligatorio');
            return;
        }
        
        const montoATransferir = parseFloat(cleanCurrencyFormat(montoTransferencia));
        if (!montoTransferencia || montoATransferir <= 0) {
            message.error('Por favor ingrese un monto válido');
            return;
        }

        // Verificar si hay saldo suficiente
        if (montoATransferir > saldoDisponible) {
            message.error('Saldo insuficiente para realizar la transferencia');
            return;
        }

        setIsConfirmModalVisible(true);
    };

    const realizarTransferencia = async () => {
        try {
            setIsSubmitting(true);
            const user_id = localStorage.getItem('usuario_id');
            const token = localStorage.getItem('token');

            const response = await fetch(`https://99envios.app/api/online/transferir-sucursal/${user_id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    codigo_sucursal_envio: detalleSucursal.codigo_sucursal,
                    monto: parseFloat(cleanCurrencyFormat(montoTransferencia)),
                    descripcion: descripcion
                })
            });

            if (!response.ok) {
                throw new Error('Error al realizar la transferencia');
            }

            const data = await response.json();
            message.success(data.success);
            
            // Limpiar estados y cerrar modales
            setIsConfirmModalVisible(false);
            setIsTransferenciaModalVisible(false);
            setMontoTransferencia('');
            setDescripcion('');
            setCorreoSucursal('');
            setDetalleSucursal(null);
            
            // Actualizar datos
            fetchSaldoDisponible();
            fetchTransferencias();
        } catch (error) {
            message.error(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'fecha_transferencia',
            key: 'fecha_transferencia',
            render: (text) => (
                <Tooltip title={new Date(text).toLocaleString()}>
                    {new Date(text).toLocaleDateString()}
                </Tooltip>
            )
        },
        {
            title: 'Sucursal',
            dataIndex: 'recibe',
            key: 'recibe',
            render: (text) => (
                <Badge status="processing" text={`Sucursal ${text}`} />
            )
        },
        {
            title: 'Monto',
            dataIndex: 'monto',
            key: 'monto',
            render: (monto, record, index) => (
                <span style={{ 
                    color: record.tipo === 'enviada' ? '#ff4d4f' : '#52c41a',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px'
                }}>
                    ${parseFloat(monto).toLocaleString()} {record.tipo === 'enviada' ? '↑' : '↓'}
                </span>
            )
        },
        {
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            render: (text) => (
                <Tooltip title={text}>
                    {text.length > 30 ? `${text.substring(0, 30)}...` : text}
                </Tooltip>
            )   
        }
    ];

    // Actualizar la configuración de la tabla con altura fija
    const tableProps = {
        bordered: true,
        size: 'large',
        scroll: { x: true, y: 400 }, // Añadir scroll vertical
        pagination: {
            pageSize: 7,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} transferencias`,
            pageSizeOptions: ['7', '10', '20', '50'],
            position: ['bottomCenter'] // Centrar la paginación
        }
    };

    // Modal para ingresar correo
    const renderCorreoModal = () => (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <SendOutlined style={{ fontSize: '24px' }} />
                    <span>Buscar Sucursal</span>
                </div>
            }
            visible={isCorreoModalVisible}
            onOk={fetchDetalleSucursal}
            onCancel={() => setIsCorreoModalVisible(false)}
            okText="Buscar"
            cancelText="Cancelar"
            className="custom-modal-transferencia"
            width={500}
            centered
          
            maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }} // Oscurecer más el fondo
        >
            <Form.Item label="Correo de la Sucursal">
                <Input
                    type="email"
                    value={correoSucursal}
                    onChange={(e) => setCorreoSucursal(e.target.value)}
                    placeholder="Ingrese el correo de la sucursal"
                />
            </Form.Item>
        </Modal>
    );

    // Modal para confirmar detalles
    const renderDetalleModal = () => (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <InfoCircleOutlined style={{ fontSize: '24px' }} />
                    <span>Detalles de la Sucursal</span>
                </div>
            }
            visible={isDetalleModalVisible}
            onOk={handleDetalleConfirm}
            onCancel={() => setIsDetalleModalVisible(false)}
            okText="Continuar"
            cancelText="Cancelar"
            className="custom-modal-transferencia"
            width={600}
            centered
        >
            {detalleSucursal && (
                <div className="detalle-sucursal">
                    <p><strong>Código:</strong> {detalleSucursal.codigo_sucursal}</p>
                    <p><strong>Nombre:</strong> {detalleSucursal.nombre_sucursal}</p>
                    <p><strong>Correo:</strong> {detalleSucursal.correo}</p>
                    <p><strong>Dirección:</strong> {detalleSucursal.direccion}</p>
                    <p><strong>Teléfono:</strong> {detalleSucursal.telefono}</p>
                </div>
            )}
        </Modal>
    );

    // Modal para realizar transferencia
    const renderTransferenciaModal = () => (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <DollarOutlined style={{ fontSize: '24px' }} />
                    <span>Realizar Transferencia</span>
                </div>
            }
            visible={isTransferenciaModalVisible}
            onOk={handlePreTransferencia}
            onCancel={() => setIsTransferenciaModalVisible(false)}
            okText="Transferir"
            cancelText="Cancelar"
            className="custom-modal-transferencia"
            width={500}
            centered
        >
            <Form layout="vertical">
                <div style={{ marginBottom: '20px', padding: '10px', background: '#f5f5f5', borderRadius: '8px' }}>
                    <strong>Saldo Disponible:</strong> ${saldoDisponible.toLocaleString()}
                </div>
                <Form.Item label="Monto a Transferir">
                    <Input
                        value={formatToCurrency(montoTransferencia)}
                        onChange={(e) => {
                            const valor = cleanCurrencyFormat(e.target.value);
                            if (parseFloat(valor) > saldoDisponible) {
                                message.warning('El monto excede el saldo disponible');
                            }
                            setMontoTransferencia(valor);
                        }}
                        prefix="$"
                        placeholder="0"
                        status={parseFloat(cleanCurrencyFormat(montoTransferencia)) > saldoDisponible ? 'error' : ''}
                    />
                    {parseFloat(cleanCurrencyFormat(montoTransferencia)) > saldoDisponible && (
                        <div style={{ color: '#ff4d4f', fontSize: '12px', marginTop: '4px' }}>
                            El monto excede el saldo disponible
                        </div>
                    )}
                </Form.Item>
                <Form.Item 
                    label="Concepto de transferencia"
                    required
                    tooltip="Este campo es obligatorio"
                >
                    <TextArea
                        value={descripcion}
                        onChange={(e) => setDescripcion(e.target.value)}
                        placeholder="Ingrese una descripción para la transferencia"
                        rows={4}
                        status={!descripcion.trim() ? 'error' : ''}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );

    // Añadir el modal de confirmación
    const renderConfirmModal = () => (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <CheckCircleOutlined style={{ fontSize: '24px', color: '#52c41a' }} />
                    <span>Confirmar Transferencia</span>
                </div>
            }
            visible={isConfirmModalVisible}
            onOk={realizarTransferencia}
            onCancel={() => !isSubmitting && setIsConfirmModalVisible(false)}
            okText="Confirmar"
            cancelText="Cancelar"
            okButtonProps={{ 
                loading: isSubmitting,
                disabled: isSubmitting 
            }}
            cancelButtonProps={{
                disabled: isSubmitting
            }}
            closable={!isSubmitting}
            maskClosable={!isSubmitting}
            keyboard={!isSubmitting}
            className="custom-modal-transferencia"
            width={500}
            centered
        >
            <div className="confirm-details">
                <h3>Por favor confirma los siguientes datos:</h3>
                <div className="detail-item">
                    <strong>Sucursal Destino:</strong>
                    <p>{detalleSucursal?.nombre_sucursal}</p>
                </div>
                <div className="detail-item">
                    <strong>Código Sucursal:</strong>
                    <p>{detalleSucursal?.codigo_sucursal}</p>
                </div>
                <div className="detail-item">
                    <strong>Correo:</strong>
                    <p>{detalleSucursal?.correo}</p>
                </div>
                <div className="detail-item">
                    <strong>Monto a Transferir:</strong>
                    <p className="monto">${formatToCurrency(montoTransferencia)}</p>
                </div>
                <div className="detail-item">
                    <strong>Concepto:</strong>
                    <p>{descripcion}</p>
                </div>
            </div>
        </Modal>
    );

    return (
        <div className="transferencia-container">
            <Card className="saldo-card">
                <div className="saldo-header">
                    <DollarOutlined className="saldo-icon" />
                    <div>
                        <h2>Saldo Disponible</h2>
                        <h3>${saldoDisponible.toLocaleString()}</h3>
                    </div>
                </div>
            </Card>

            <Button 
                type="primary"
                icon={<SendOutlined />}
                onClick={() => setIsCorreoModalVisible(true)}
                className="nueva-transferencia-btn"
                size="large"
            >
                Nueva Transferencia
            </Button>

            <div className="historial-transferencias">
                <Card 
                    title={
                        <div className="card-title">
                            <span>Transferencias Enviadas</span>
                            <Tooltip title="Transferencias realizadas a otras sucursales">
                                <InfoCircleOutlined style={{ marginLeft: 8 }} />
                            </Tooltip>
                        </div>
                    }
                    className="transferencias-card"
                >
                    <Table 
                        {...tableProps}
                        dataSource={transferenciasEnviadas.map(t => ({...t, tipo: 'enviada'}))}
                        columns={columns}
                        rowKey="id"
                        locale={{
                            emptyText: <Empty 
                                description="No hay transferencias enviadas"
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                        }}
                    />
                </Card>

                <Card 
                    title={
                        <div className="card-title">
                            <span>Transferencias Recibidas</span>
                            <Tooltip title="Transferencias recibidas de otras sucursales">
                                <InfoCircleOutlined style={{ marginLeft: 8 }} />
                            </Tooltip>
                        </div>
                    }
                    className="transferencias-card"
                >
                    <Table 
                        {...tableProps}
                        dataSource={transferenciasRecibidas.map(t => ({...t, tipo: 'recibida'}))}
                        columns={columns}
                        rowKey="id"
                        locale={{
                            emptyText: <Empty 
                                description="No hay transferencias recibidas"
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                        }}
                    />
                </Card>
            </div>

            {renderCorreoModal()}
            {renderDetalleModal()}
            {renderTransferenciaModal()}
            {renderConfirmModal()}
        </div>
    );
};

export default TransferenciaSucursal;

import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { message } from 'antd';
import './NavbarMex.css';

const NavbarMex = ({ title }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [nombreSucursal, setNombreSucursal] = useState('');
  const [isShopifyActive, setIsShopifyActive] = useState(false);


  useEffect(() => {
    const fetchShopifyData = async () => {
        try {
            const userId = localStorage.getItem('usuario_id');
            const response = await fetch(`https://integration.99envios.app/api/integration/shopify/v1/verificar-sucursal/${userId}`);
            const data = await response.json();
            console.log("DAtos de shopify",data);

            // Verifica si el usuario tiene acceso a Shopify
            if (data.message === "El suscriptor está activo.") {
                setIsShopifyActive(true);
            }
        } catch (error) {
            console.error('Error al verificar el acceso a Shopify:', error);
        }
    };

    fetchShopifyData();
}, []);

  const menuRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const storedId = localStorage.getItem('usuario_id');
    if (storedId) {
      setUserId(storedId);
    }

    // Función para manejar el cambio de tamaño de la ventana
    const handleResize = () => {
      const mobileView = window.innerWidth < 768;
      setIsMobile(mobileView);

      // Si el ancho supera los 768px (vista normal), abrimos el menú automáticamente
      if (!mobileView) {
        setMenuOpen(true);
      }
    };

    // Detectar clic fuera de la barra de navegación solo en pantallas móviles
    const handleClickOutside = (event) => {
      if (isMobile && menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    // Añadir el evento de clic y redimensionamiento de la ventana
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleResize);

    // Limpiar eventos al desmontar el componente
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = () => {
    setSubMenuOpen(!isSubMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');
    window.location.href = '/';
    message.success('Sesión cerrada exitosamente');
  };

  
  const rol_id = localStorage.getItem('rol_id');


  useEffect(() => {
    const fetchSucursal = async () => {
      const userId = localStorage.getItem('usuario_id');
      const token = localStorage.getItem('token'); // Asumiendo que necesitas el token para la autorización
  
      try {
        const response = await fetch(`https://99envios.app/api/online/sucursal/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Error al obtener la sucursal');
        }
        const data = await response.json();
        setNombreSucursal(data.nombre_sucursal);
      } catch (error) {
        console.error('Error al obtener la sucursal:', error);
      }
    };
  
    fetchSucursal();
  }, []);
  

  return (
    <>
      <div className="navbar-top">
        <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <div className="navbar-tittle">
          <p>{title}</p>
        </div>
        <div className="user-info">
          <p>Hola, {nombreSucursal}</p>
          <NavLink to="/panel-usuario" className="panel-link">
            <p className="panel-link">99Panel</p>
          </NavLink>
          <div className="user-avatar">
            <img src="/Images/avatar-repartidor.jpg" alt="Avatar" />
          </div>
        </div>
      </div>
      <div className={`navbar-container ${isMenuOpen ? 'show' : 'hide'}`} ref={menuRef}>
        <div className="close-btn" onClick={toggleMenu}>X</div>
        <div className="navbar-title">
          <img src="/Images/download.png" alt="Download" className="logo" />
        </div>
        <div className="navbar-menu">
          <ul>
            <li>
              <NavLink to="/dashboardMex" activeClassName="active">
                <span className="material-icons">dashboard</span>Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to={`/guiaForm/${userId}`} activeClassName="active">
                <span className="material-icons">description</span>Guías Unitarias
              </NavLink>
            </li>
            {/* <li>
              <NavLink to={`/guias-masivo/${userId}`} activeClassName="active">
                <span className="material-icons">library_books</span>Guías Masivo (BETA 10 máximo)
              </NavLink>
            </li> */}
            <li>
              <NavLink to={`/masivo-final/${userId}`} activeClassName="active">
                <span className="material-icons">dynamic_feed</span>Guías Masivo Final
              </NavLink>
            </li>
            <li>
              <NavLink to="/buscar-guia" activeClassName="active">
                <span className="material-icons">search</span>Rastrear Guía
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/desactivar-guia" activeClassName="active">
                <span className="material-icons">block</span>Desactivar guía
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/novedades" activeClassName="active">
                <span className="material-icons">insert_chart_outlined</span>Novedades
              </NavLink>
            </li>
            <li>
              <NavLink to="/cartera-completa" activeClassName="active">
                <span className="material-icons">account_balance_wallet</span>Cartera completa
              </NavLink>
            </li>
            <li>
              <NavLink to="/envios-completos" activeClassName="active">
                <span className="material-icons">local_shipping</span>Envios completos
              </NavLink>
            </li>
            <li>
              <NavLink to="/recoleccion" activeClassName="active">
                <span className="material-icons">shopping_cart</span>Recolección
              </NavLink>
            </li>
{/* WHATSAPP */}
            <li>
              <NavLink to="/whatsapp" activeClassName="active">
                <span className="material-icons">chat</span>Activación WhatsApp
              </NavLink>
            </li>
            <li>
              <NavLink to="/retiros" activeClassName="active">
                <span className="material-icons">pending_actions</span>Retiros
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/bitacora-cartera" activeClassName="active">
                <span className="material-icons">book</span>Bitácora Cartera
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/auto-pqr" activeClassName="active">
                <span className="material-icons">account_circle</span>Autogestión
              </NavLink>
            </li>
            {/* Shopify */}
            {isShopifyActive && (
              <li>
                <NavLink to="/shopify" activeClassName="active">
                  <span className="material-icons">shop</span>Shopify
                </NavLink>
              </li>
            )}
            {isShopifyActive && (
              <li>
                <NavLink to="/ordenes-shopify" activeClassName="active">
                  <span className="material-icons">shop</span>Órdenes Shopify
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to="https://99envios.com/recargas/" target='_blank' activeClassName="active">
                <span className="material-icons">account_balance_wallet</span>Recargas
              </NavLink>
            </li>
            <li>
              <NavLink to="/dropshippingGlobal" activeClassName="active">
                <span className="material-icons">public</span>Dropshipping General
              </NavLink>
            </li>

            <li>
              <NavLink to="/tutoriales" activeClassName="active">
                <span className="material-icons">insert_chart_outlined</span>Tutoriales
              </NavLink>
            </li>
            {rol_id === '1' && (
              <li>
                <NavLink exact to="/dashboardAdmin" activeClassName="active">
                  <span className="material-icons">AdminPanelSettings</span>Dashboard Admin
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to="/" onClick={handleLogout} activeClassName="active">
                <span className="material-icons">exit_to_app</span>Cerrar sesión
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavbarMex;